/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";


body {
    letter-spacing:0.02rem;
}


/* width */
::-webkit-scrollbar {
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}


.p-tooltip .p-tooltip-text {
    width: max-content;
}